var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('Message',{attrs:{"message":_vm.message},on:{"closeAlert":function($event){_vm.message.show = false}}}),_c('v-col',{attrs:{"cols":"12","sm":"4","lg":"2"}},[_c('h4',[_vm._v("Hochzeitsstatus "),_c('v-tooltip',{attrs:{"right":"","color":"","max-width":"200px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{staticClass:"d-inline-flex",attrs:{"size":"20"}},[_vm._v(" mdi-help ")])],1)]}}])},[_vm._v(" Lege in welchem Status sich eine Hochzeit befinden kann ")])],1)]),_c('v-col',[_vm._l((_vm.weddingStatus),function(status){return _c('div',{key:status.id,staticClass:"pb-3"},[_vm._v(" "+_vm._s(status.position)+". "+_vm._s(status.name)+" "),(status.position >1)?_c('v-tooltip',{attrs:{"right":"","color":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{on:{"click":function($event){return _vm.moveStatus('up', status.position-1, status.id)}}},[_vm._v(" mdi-arrow-up ")])],1)]}}],null,true)},[_vm._v(" Nach oben ")]):_vm._e(),(status.position < _vm.weddingStatus.length)?_c('v-tooltip',{attrs:{"right":"","color":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('spanv',_vm._g(_vm._b({},'spanv',attrs,false),on),[_c('v-icon',{on:{"click":function($event){return _vm.moveStatus('down', status.position+1, status.id)}}},[_vm._v(" mdi-arrow-down ")])],1)]}}],null,true)},[_vm._v(" Nach unten ")]):_vm._e(),(status.position === _vm.weddingStatus.length)?_c('v-tooltip',{attrs:{"right":"","color":"red"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('spanv',_vm._g(_vm._b({},'spanv',attrs,false),on),[_c('v-icon',{on:{"click":function($event){_vm.sureToDeleteDialog = true; _vm.selectedId = status.id}}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_vm._v(" Status löschen ")]):_vm._e()],1)}),_c('SureToDelete',{staticClass:"pt-0 mt-0 pb-0 mb-0",attrs:{"dialog":_vm.sureToDeleteDialog,"text":'Bist du Sicher, dass du den Status löschen möchtest? Bitte prüfe zunächst, ob sich noch Hochzeiten in diesem Status befinden.'},on:{"cancel":function($event){_vm.sureToDeleteDialog=false},"sure":function($event){_vm.deleteStatus('weddingStatus'); _vm.sureToDeleteDialog=false}}}),_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"cols":"8","md":"6"}},[_c('v-text-field',{attrs:{"placeholder":"Neuer Status"},model:{value:(_vm.newWeddingStatus),callback:function ($$v) {_vm.newWeddingStatus=$$v},expression:"newWeddingStatus"}})],1),_c('v-col',{attrs:{"cols":"4","md":"6"}},[_c('a',{staticClass:"d-inline",on:{"click":function($event){return _vm.addWeddingStatus(_vm.newWeddingStatus)}}},[_vm._v(" Hinzufügen ")])])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }